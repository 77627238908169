import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Router, Route } from 'react-router-dom';
import App from './App';
import { createBrowserHistory } from "history";
import './css/main.less'

const history = createBrowserHistory()

const Root = ({ store }) => (
    <Provider store={store}>
            <Router history={history}>
                <div>
                    <Route path="/" exact component={App} />
                    <Route path="/:path/:subPath?" component={App} />
                </div>
            </Router>
    </Provider>
)

Root.propTypes = {
  store: PropTypes.object.isRequired
}

export default Root
