export const GETTING_ALL_USER_DATA = 'GETTING_ALL_USER_DATA';
export const GOT_ALL_USER_DATA = 'GOT_ALL_USER_DATA';
export const ERROR_GETTING_ALL_USER_DATA = 'ERROR_GETTING_ALL_USER_DATA';

export const UPDATING_USER_DATA = 'UPDATING_USER_DATA';
export const UPDATED_USER_DATA = 'UPDATED_USER_DATA';
export const ERROR_UPDATING_USER_DATA = 'ERROR_UPDATING_USER_DATA';

export const CREATING_NEW_USER = 'CREATING_NEW_USER';
export const CREATED_NEW_USER = 'CREATED_NEW_USER';
export const ERROR_CREATING_NEW_USER = 'ERROR_CREATING_NEW_USER';

export const REMOVING_USER = 'REMOVING_USER';
export const REMOVED_USER = 'REMOVED_USER';
export const ERROR_REMOVING_USER = 'ERROR_REMOVING_USER';
import { API } from 'aws-amplify';

export function UsersGetAllAction() {
	return dispatch => {
		dispatch({
			type: GETTING_ALL_USER_DATA,
			notice: {
				type: 'info',
				message: 'Getting all user data',
				show: false
			}
		});
		return API.get('dealerships', '/users').then(response => {
			dispatch({
				type: GOT_ALL_USER_DATA,
				data: response,
				notice: {
					type: 'success',
					message: 'Successfully received all user data',
					show: false
				}
			});
		}).catch(error => {
			dispatch({
				type: ERROR_GETTING_ALL_USER_DATA,
				notice: {
					type: 'error',
					message: 'Failed to received user data',
					show: true
				},
				error: error
			});
		});
	}
}

export function UserDeleteAction(id) {
	return dispatch => {
		dispatch({
			type: REMOVING_USER,
			notice: {
				type: 'info',
				message: 'Removing user',
				show: false
			}
		});
		return API.get('dealerships', '/users').then(response => {
            // return API.delete('dealerships', '/users').then(response => {
			dispatch({
				type: REMOVED_USER,
				data: response,
				notice: {
					type: 'success',
					message: 'Successfully removed user',
					show: false
				}
			});
		}).catch(error => {
			dispatch({
				type: ERROR_REMOVING_USER,
				notice: {
					type: 'error',
					message: 'Failed to remove',
					show: true
				},
				error: error
			});
		});
	}
}

export function UserUpdateAction(id) {
	return dispatch => {
		dispatch({
			type: UPDATING_USER_DATA,
			notice: {
				type: 'info',
				message: 'Updating user data',
				show: false
			}
		});
		return API.get('dealerships', '/users').then(response => {
            // return API.post('dealerships', '/users').then(response => {
			dispatch({
				type: UPDATED_USER_DATA,
				data: response,
				notice: {
					type: 'success',
					message: 'Successfully updated user data',
					show: false
				}
			});
		}).catch(error => {
			dispatch({
				type: ERROR_UPDATING_USER_DATA,
				notice: {
					type: 'error',
					message: 'Failed to update user data',
					show: true
				},
				error: error
			});
		});
	}
}

export function UserCreateAction(data) {
	return dispatch => {
		dispatch({
			type: CREATING_NEW_USER,
			notice: {
				type: 'info',
				message: 'Creating new user',
				show: false
			}
		});
		return API.get('dealerships', '/users').then(response => {
            // return API.put('dealerships', '/users').then(response => {
			dispatch({
				type: CREATED_NEW_USER,
				data: response,
				notice: {
					type: 'success',
					message: 'Successfully created new user',
					show: true
				}
			});
		}).catch(error => {
			dispatch({
				type: ERROR_CREATING_NEW_USER,
				notice: {
					type: 'error',
					message: 'Failed to create new user',
					show: true
				},
				error: error
			});
		});
	}
}