import React, { Component } from 'react';
import { 
	Container,
	Typography, 
	InputBase, 
	IconButton,
	Paper
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import '../css/home'

export default class Home extends Component {
	constructor(props) {
		super(props);
	}

	render() {
        return( 
            <div className="home">
				<div className="intro">
					<Container maxWidth="md">
						<Typography variant="h2" align="center" gutterBottom={true} color="primary">
							Car Payment Pro
						</Typography>
						<Typography variant="h5" align="center"paragraph={true} color="textSecondary">
							Search for a vehicle based on any number of attributes.
						</Typography>
						<Paper component="form" className="searchBarParent">
							<InputBase
								className="searchBar"
								placeholder="Search Vehicles"
								inputProps={{ 'aria-label': 'search vehicles' }}
								color="secondary"
							/>
							<IconButton type="submit" aria-label="search" className="searchBtn">
								<SearchIcon />
							</IconButton>
						</Paper>
					</Container>
				</div>
            </div>
        );
	}
}