import React, { Component } from 'react';
import { API, Auth } from 'aws-amplify';
import { AppBar, Toolbar } from '@material-ui/core';
import { IconButton, Button } from '@material-ui/core';
import {NavLink} from 'react-router-dom';
import { Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Drawer from '@material-ui/core/Drawer';
import HomeIcon from '@material-ui/icons/Home';
import GroupIcon from '@material-ui/icons/Group';
import '../css/header.less';

const navLinks = [
	{navTitle: 'Home', link: '/home', icon: (<HomeIcon/>)},
	{navTitle: 'User Management', link: '/user-management', icon: (<GroupIcon/>)}
];

export default class Header extends Component {
    state = {
		isOpen: false
    }
    
	constructor(props) {
		super(props);
    }

	toggleDrawer = (open) => event => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}

		this.setState({ ...this.state, isOpen: open });
    };
    
    handleLogout = async event => {
		await Auth.signOut();

		this.userHasAuthenticated(false);
		this.props.history.push('/login');
    };
    
	navList = () => (
		<div
			role="presentation"
			onClick={this.toggleDrawer(false)}
			onKeyDown={this.toggleDrawer(false)}
		>
			<List>
			{navLinks.map((navObj) => (
				<ListItem button key={navObj.navTitle}>
					<NavLink
						to={navObj.link}
						style={ {
							textDecoration: 'none',
							color: 'grey'
						}}
						activeStyle={ {
							textDecoration: 'none',
							color: '#2196f3'
						}}>
                            <Typography align='center' className="navItems">
                                {navObj.icon}
                                <span className="navTitle">
                                    {navObj.navTitle}
                                </span>
                            </Typography>
					</NavLink>
				</ListItem>
			))}
			</List>
		</div>
    );

	render() {
        const {isAuthenticated} = this.props;
        return( 
            <div className="header">
                <AppBar position="static">
                    <Toolbar>
                        {
                            isAuthenticated && 
                            <div>
                                <IconButton onClick={this.toggleDrawer(true)} edge="start" color="inherit" aria-label="menu">
                                    <MenuIcon />
                                </IconButton>
                            </div>
                        }
                        <div className="hd-title">
                            <Typography variant="h6">
                                CarPaymentPro
                            </Typography>
                        </div>
                        <div className="head-login">
                            {isAuthenticated ? 
                                <Button color="inherit" onClick={() => this.handleLogout()}>Log Out</Button> :
                                <Button color="inherit" onClick={() => Auth.federatedSignIn()}>Login</Button>
                            }
                        </div>
                    </Toolbar>
                </AppBar>
                <Drawer className="navDrawer" open={this.state.isOpen} onClose={this.toggleDrawer(false)}>
					{this.navList()}
				</Drawer>
            </div>
        );
	}
}