let isAdmin = window.location.hostname.split('.')[0] === 'admin';

export default {
	apiGateway: {
		REGION: 'us-east-2',
		URL: 'https://uci5ngjhwi.execute-api.us-east-2.amazonaws.com/production'
	},
	cognito: {
		REGION: 'us-east-2',
		USER_POOL_ID: 'us-east-2_5lvlwE5GP',
		APP_CLIENT_ID: isAdmin ? '2f8bjolvcnnokuojqqc1pia0b6' : '6p6c54me3l82vh33cnos9t07da',
		DOMAIN: isAdmin ? 'admin-auth.carpaymentpro.com' : 'auth.carpaymentpro.com',
		SCOPE: ['email', 'openid'],
		REDIRECT_SIGN_IN: isAdmin ? 'https://admin.carpaymentpro.com' : 'https://carpaymentpro.com',
		REDIRECT_SIGN_OUT: isAdmin ? 'https://admin.carpaymentpro.com' : 'https://carpaymentpro.com',
		RESPONSE_TYPE: 'code'
	},
	isAdmin: isAdmin
};