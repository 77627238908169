import {
	GETTING_ALL_USER_DATA,
	GOT_ALL_USER_DATA,
	ERROR_GETTING_ALL_USER_DATA,
	UPDATING_USER_DATA,
	UPDATED_USER_DATA,
	ERROR_UPDATING_USER_DATA,
	CREATING_NEW_USER,
	CREATED_NEW_USER,
	ERROR_CREATING_NEW_USER,
	REMOVING_USER,
	REMOVED_USER,
	ERROR_REMOVING_USER
} from '../../actions/UserActions';

let all_user_default = {
    list: [],
    loading: false,
    error: {}
}

export function UsersGetAllReducer (state=all_user_default, action) {
    switch (action.type) {
        case GETTING_ALL_USER_DATA:
            return {
                ...state,
                loading: true
            };
        case GOT_ALL_USER_DATA:
            return {
                ...state,
                loading: false,
                list: [...action.data.Items]
            };
        case ERROR_GETTING_ALL_USER_DATA:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
}

let remove_default = {
    loading: false,
    error: {}
}
export function UserDeleteReducer (state=remove_default, action) {
    switch (action.type) {
        case REMOVING_USER:
            return {
                ...state,
                loading: true
            };
        case REMOVED_USER:
            return {
                ...state,
                loading: false
            };
        case ERROR_REMOVING_USER:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
}


let update_default = {
    loading: false,
    error: {}
}
export function UserUpdateReducer (state=update_default, action) {
    switch (action.type) {
        case UPDATING_USER_DATA:
            return {
                ...state,
                loading: true
            };
        case UPDATED_USER_DATA:
            return {
                ...state,
                loading: false
            };
        case ERROR_UPDATING_USER_DATA:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
}

let create_default = {
    loading: false,
    error: {}
}

export function UserCreateReducer (state=create_default, action) {
    switch (action.type) {
        case CREATING_NEW_USER:
            return {
                ...state,
                loading: true
            };
        case CREATED_NEW_USER:
            return {
                ...state,
                loading: false
            };
        case ERROR_CREATING_NEW_USER:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
}