import { createStore, applyMiddleware, combineReducers , compose} from 'redux'
import thunkMiddleware from 'redux-thunk';
import loginReducer from './redux/reducers/login/loginReducer';
import {UsersGetAllReducer, UserDeleteReducer, UserUpdateReducer, UserCreateReducer} from './redux/reducers/user_data/UserReducers'
import invariant from 'redux-immutable-state-invariant';


export default function configureStore(preloadedState) {
	const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
	const reducers = combineReducers({
		loginReducer,
		UsersGetAllReducer,
		UserDeleteReducer,
		UserUpdateReducer,
		UserCreateReducer
	});
	return createStore(
		reducers,
	    preloadedState,
	    composeEnhancers(
		    applyMiddleware(
		    	invariant(),
		     	thunkMiddleware
		    )
	    )
	)
}
