import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    Container,
	Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Fab,
    IconButton,
    Modal,
    Backdrop,
    Fade,
    TextField,
    Button,
    Paper
} from '@material-ui/core';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import {
    UsersGetAllAction, 
    UserDeleteAction,
    UserUpdateAction,
    UserCreateAction
} from '../redux/actions/UserActions';
import moment from 'moment';
import '../css/users.less'

class UserMgmt extends Component {
    state = {
        newUserModalOpen: false,
        updateUserModalOpen: false,
        newUser: {
			name: '',
            email: '',
            dealership_store: '',
            dealership_group: '',
            OEM: '',
            enterprise: '',
            region: ''
        },
        updateUser: {
			name: '',
            email: '',
            dealership_store: '',
            dealership_group: '',
            OEM: '',
            enterprise: '',
            region: ''
		},
    };

	constructor(props) {
		super(props);
	}

	componentDidMount() {
        this.getAllUser();
	}

	getAllUser() {
        const {dispatch} = this.props;
        dispatch(UsersGetAllAction());
    }
    
    removeUser(id) {
        const {dispatch} = this.props;
        dispatch(UserDeleteAction(id));
    }
    
    updateUser() {
        const {updateUser} = this.state;
        const {dispatch} = this.props;
        console.log("Updating user", newUser)
        dispatch(UserUpdateAction(updateUser));
    }
    
    createNewUser(data) {
        const {newUser} = this.state;
        const {dispatch} = this.props;
        console.log("creating new user", newUser)
        dispatch(UserCreateAction(newUser));
    }
    
    handleNewOpen = () => {
        this.setState({newUserModalOpen: true});
    };
    
    handleNewClose = () => {
        this.setState({
            newUserModalOpen: false, 
            newUser: {
                name: '',
                email: '',
                dealership_store: '',
                dealership_group: '',
                OEM: '',
                enterprise: '',
                region: ''
            }
        });
    };

    handleUpdateOpen = (userData) => {
        this.setState({updateUserModalOpen: true, updateUser: {...userData}});
    };
    
    handleUpdateClose = () => {
        this.setState({
            updateUserModalOpen: false,
            updateUser: {
                name: '',
                email: '',
                dealership_store: '',
                dealership_group: '',
                OEM: '',
                enterprise: '',
                region: ''
            }
        });
    };

    onNewUserFieldChange = e => {
		const newFormData = {...this.state.newUser}

		newFormData[e.target.name] = e.target.value;

		this.setState({
			newUser: newFormData
		});
    }
    
    onUpdateUserFieldChange = e => {
		const updateFormData = {...this.state.updateUser}

		updateFormData[e.target.name] = e.target.value;

		this.setState({
			updateUser: updateFormData
		});
	}

	render() {
        const {userList} = this.props;
        const {newUserModalOpen, updateUserModalOpen, newUser, updateUser} = this.state;

        console.log(userList);
        return( 
            <div className="user_mgmt">
				<Container maxWidth="xl">
                    <Typography variant="h4" align="left" gutterBottom={true} color="primary">
                        User Management
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Name</TableCell>
                                    <TableCell align="left">Email</TableCell>
                                    <TableCell align="left">Created Date</TableCell>
                                    <TableCell align="left">Dealership Store</TableCell>
                                    <TableCell align="left">User ID</TableCell>
                                    <TableCell align="left">Dealership Group</TableCell>
                                    <TableCell align="left">OEM</TableCell>
                                    <TableCell align="left">Enterprise</TableCell>
                                    <TableCell align="left">Region</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userList.map(user => {
                                    return (
                                        <TableRow key={user.userId}>
                                            <TableCell>{user.name}</TableCell>
                                            <TableCell>{user.email}</TableCell>
                                            <TableCell>{moment(user.createdDate).format('LL')}</TableCell>
                                            <TableCell>{user.dealership_store}</TableCell>
                                            <TableCell>{user.userId}</TableCell>
                                            <TableCell>{user.dealership_group}</TableCell>
                                            <TableCell>{user.OEM}</TableCell>
                                            <TableCell>{user.enterprise}</TableCell>
                                            <TableCell>{user.region}</TableCell>
                                            <TableCell align="right">
                                                <IconButton aria-label="update" color="primary" onClick={() => {this.handleUpdateOpen(user)}}>
                                                    <EditIcon></EditIcon>
                                                </IconButton>
                                                <IconButton aria-label="delete" color="secondary" onClick={() => {this.removeUser(user.email)}}>
                                                    <DeleteForeverIcon></DeleteForeverIcon>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Fab className="add_user" color="primary" aria-label="add" onClick={() => {this.handleNewOpen(true)}}>
                        <AddIcon />
                    </Fab>
                </Container>
                <Modal
                    aria-labelledby="new-user-modal"
                    aria-describedby="open-modal-for-new-user"
                    open={newUserModalOpen}
                    className="userModal"
                    onClose={() => this.handleNewClose()}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={newUserModalOpen} className="userFade">
                        <div>
                            <h2 id="new-user-modal">Add New User</h2>
                            <div className="formData">
                                <TextField 
                                    label="Name"
                                    margin="normal"
                                    onChange={this.onNewUserFieldChange}
                                    name="name"
                                    value={newUser.name}                                    
                                />
                                <TextField 
                                    label="Email"
                                    margin="normal"
                                    onChange={this.onNewUserFieldChange}
                                    name="email"
                                    value={newUser.email}                                    
                                />
                                <TextField 
                                    label="Dealership Store"
                                    margin="normal"
                                    onChange={this.onNewUserFieldChange}
                                    name="dealership_store"
                                    value={newUser.dealership_store}                                    
                                />
                                <TextField 
                                    label="Dealership Group"
                                    margin="normal"
                                    onChange={this.onNewUserFieldChange}
                                    name="dealership_group"
                                    value={newUser.dealership_group}                                    
                                />
                                <TextField 
                                    label="OEM"
                                    margin="normal"
                                    onChange={this.onNewUserFieldChange}
                                    name="OEM"
                                    value={newUser.OEM}                                    
                                />
                                <TextField 
                                    label="Enterprise"
                                    margin="normal"
                                    onChange={this.onNewUserFieldChange}
                                    name="enterprise"
                                    value={newUser.enterprise}                                    
                                />
                                <TextField 
                                    label="Region"
                                    margin="normal"
                                    onChange={this.onNewUserFieldChange}
                                    name="region"
                                    value={newUser.region}                                    
                                />
                            </div>
                            <div className="formActions">
                                <Button variant="contained" onClick={() => this.handleNewClose()}>Cancel</Button>
                                <Button variant="contained" color="primary" onClick={() => this.createNewUser()}>
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </Fade>
                </Modal>
                <Modal
                    aria-labelledby="update-user-modal"
                    aria-describedby="form-to-update-user-data"
                    open={updateUserModalOpen}
                    className="userModal"
                    onClose={() => this.handleUpdateClose()}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={updateUserModalOpen} className="userFade">
                        <div>
                            <h2 id="update-user-modal">Update User Data</h2>
                            <div className="formData">
                                <TextField 
                                    label="Name"
                                    margin="normal"
                                    onChange={this.onUpdateUserFieldChange}
                                    name="name"
                                    value={updateUser.name}                                    
                                />
                                <TextField 
                                    label="Email"
                                    margin="normal"
                                    onChange={this.onUpdateUserFieldChange}
                                    name="email"
                                    value={updateUser.email}                                    
                                />
                                <TextField 
                                    label="Dealership Store"
                                    margin="normal"
                                    onChange={this.onUpdateUserFieldChange}
                                    name="dealership_store"
                                    value={updateUser.dealership_store}                                    
                                />
                                <TextField 
                                    label="Dealership Group"
                                    margin="normal"
                                    onChange={this.onUpdateUserFieldChange}
                                    name="dealership_group"
                                    value={updateUser.dealership_group}                                    
                                />
                                <TextField 
                                    label="OEM"
                                    margin="normal"
                                    onChange={this.onUpdateUserFieldChange}
                                    name="OEM"
                                    value={updateUser.OEM}                                    
                                />
                                <TextField 
                                    label="Enterprise"
                                    margin="normal"
                                    onChange={this.onUpdateUserFieldChange}
                                    name="enterprise"
                                    value={updateUser.enterprise}                                    
                                />
                                <TextField 
                                    label="Region"
                                    margin="normal"
                                    onChange={this.onUpdateUserFieldChange}
                                    name="region"
                                    value={updateUser.region}                                    
                                />
                            </div>
                            <div className="formActions">
                                <Button variant="contained" onClick={() => this.handleUpdateClose()}>Cancel</Button>
                                <Button variant="contained" color="primary" onClose={() => this.updateUser()}>
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </Fade>
                </Modal>
            </div>
        );
	}
}
function mapStateToProps(state) {
    const {list, loading, error} = {...state.UsersGetAllReducer}

	return {
        userList: list,
        isLoading: loading,
        error: error
	}
}

export default connect(mapStateToProps)(UserMgmt);
