import React,  { Component } from 'react';
import { Auth } from 'aws-amplify';
import { Hub } from '@aws-amplify/core';
import Home from './containers/Home';
import UserMgmt from './containers/UserMgmt';
import Header from './containers/Header';
import get from 'lodash/get';
import './css/main.less'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import {blue} from '@material-ui/core/colors';

const theme = createMuiTheme({
    palette: {
        primary: blue,
        secondary: {
            main: '#f44336',
        },
    },
});

class App extends Component {
	
	constructor(props) {
		super(props);

		this.state = {
			isAuthenticated: false,
			isAuthenticating: true
		};
	}

	async componentDidMount() {
		let updateUser = async () => {
			try {
				await Auth.currentSession()
			  	this.userHasAuthenticated(true);
			} catch {
				this.userHasAuthenticated(false);
			}
		}
		Hub.listen('auth', updateUser) // listen for login/signup events
	  
		updateUser() // check manually the first time because we won't get a Hub event
		return () => Hub.remove('auth', updateUser) // cleanup
	}

	userHasAuthenticated = authenticated => {
		this.setState({ isAuthenticated: authenticated, isAuthenticating: false});
	};

	handleLogout = async event => {
		await Auth.signOut();

		this.userHasAuthenticated(false);
		this.props.history.push('/login');
	};

	loadTab (path, subPath, query) {
		const childProps = {
			isAuthenticated: this.state.isAuthenticated,
			userHasAuthenticated: this.userHasAuthenticated
		};

		console.log(path);
		switch (path) {
			case "home": 
				return (<Home subPath={subPath} query={query} {...childProps}/>);
			case "user-management":
				return (<UserMgmt subPath={subPath} query={query} {...childProps}/>)
			default:
				return(<Home subPath={subPath} query={query} {...childProps}/>);
		}
	}

	render () {
		const path = get(this, 'props.match.params.path');
		const subPath = get(this, 'props.match.params.subPath');
		const query = new URLSearchParams(get(this, 'props.location.search'));

		const {isAuthenticated} = this.state;
		return (
			    <div className="root">
    				<ThemeProvider theme={theme}>
						<Header isAuthenticated={isAuthenticated}/>
						<div className="tabPages">
							{this.loadTab(path, subPath, query)}
						</div>	
					</ThemeProvider>				
			    </div>
		)
	}
}

export default App;
